import { CurrencyPipe, IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';

import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import {
    CustomLoadingIndicatorExtendedService,
    CustomLoadingIndicatorService,
    LOADING_INDICATOR,
} from '@frontend/sports/common/loading-indicator-lib';
import { provideSafariBrowserToken } from '@frontend/ui/shared';
import { MediaQueryService, PAGE_VIEW_DATA_PROVIDER, ROUTE_PROCESSOR, STORE_PREFIX } from '@frontend/vanilla/core';
import { LANGUAGE_SWITCHER_URLS_PROVIDER } from '@frontend/vanilla/features/language-switcher';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideNgxPopperjsOptions } from 'ngx-popperjs';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { BetslipDigitalIntegrationModule } from 'packages/sports/common/betslip/integration/betslip-digital-integration.module';

import { extModules } from '../../../environments/environment';
import { AccaBoostRefreshTriggerService } from './acca-boost-base/acca-boost-refresh-trigger.service';
import { ACCA_BOOST_REFRESH_TRIGGER } from './acca-boost-base/acca-boost-token-provider.service';
import { setAppInjector } from './app-injector';
import { provideBestOdds } from './best-odds-guarantee/best-odds.provider';
import { provideAppBootstrap } from './bootstrap/app-bootstrap.provider';
import { provideCds } from './cds/cds.provider';
import { HammerGestureConfigProvider } from './common/browser/hammer-gesture.provider';
import { NgScrollbarOptionsProvider } from './common/browser/scrollbar-options.provider';
import { SCROLL_ADAPTER_CONFIG, ScrollAdapterConfig } from './common/scroll-adapter/scroll-adapter.model';
import { provideDataRefresh } from './data-refresh/data-refresh.provider';
import provideEsports from './esports/esports.provider';
import { provideEventBridge } from './event-bridge/event-bridge.provider';
import { provideEventList } from './event-list-shared/event-list-providers';
import { MarketDisplayFallbackService, SportsMarketGroupingFallbackService } from './event-model/mappers/sports-market-grouping-fallback.service';
import { provideMarqueeWidgetSkeleton } from './highlights-marquee/marquee-widget-skeleton.provider';
import { provideHome } from './home/home.provider';
import { provideSportsHttpInterceptors } from './http-interceptors/sports-http-interceptors.provider';
import { provideSportsHttp } from './http/sports-http.module';
import { provideLayout } from './layout/layout.provider';
import { PatchedMediaObserver } from './layout/patched-media-observer';
import { provideLazyFeatures } from './lazy-features/lazy-features.provider';
import { provideMarketSubTypeLogoHideStrategy } from './marketsubtype-logo/marketsubtype-logo-hide.provider';
import { MediaProviders } from './media/media.provider';
import { provideMyBetsBase } from './my-bets-base/my-bets-base.provider';
import { provideNativeApi } from './native-api/native-api.provider';
import { provideNgrxReports } from './ngrx-reports/ngrx-reports.provider';
import { provideNumpad } from './numpad/numpad.provider';
import { provideRunAfterLcp } from './perf-cdk/run-after-lcp.provider';
import { PrettyUrlLanguageSwitcherProvider } from './router/pretty-url-language-switcher.provider';
import { PrettyUrlPageViewDataProvider } from './router/pretty-url-page-view-data.provider';
import { PrettyUrlRouteProcessor } from './router/pretty-url-route-processor.service';
import { RootInjector } from './router/root-injector.service';
import { provideRootStateStorage } from './store-persist/state-storage-root.provider';
import { provideTracking } from './tracking/tracking.provider';
import { provideUiManager } from './ui-manager/ui-manager.provider';
import { provideUser } from './user/user.provider';
import { WidgetContextRefreshProcessor } from './widget/common/widget-context-refresh-processor';
import { WidgetRefreshProcessor } from './widget/core/widget-refresh.service';

//  tslint:disable-next-line:no-submodule-imports
export const SCROLL_ADAPTER_CONFIG_OVERRIDE: ScrollAdapterConfig = {
    disableHorizontalScrollOnDesktop: true,
};

@NgModule({
    imports: [
        StoreModule.forRoot(
            {},
            {
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                    strictStateSerializability: false,
                    strictActionSerializability: false,
                },
            },
        ),
        extModules,
        HammerModule,
        BetslipDigitalIntegrationModule.forRoot(),
        MatDialogModule,
        EffectsModule.forRoot([]),
    ],
    providers: [
        provideNgxPopperjsOptions(),
        provideEsports(),
        provideUser(),
        provideTracking(),
        provideUiManager(),
        MediaProviders(),
        provideSportsHttp(),
        provideSportsHttpInterceptors(),
        provideEventList(),
        provideNgrxReports(),
        provideLazyFeatures(),
        provideLayout(),
        provideNumpad(),
        provideBestOdds(),
        provideEventBridge(),
        provideDataRefresh(),
        provideNativeApi(),
        provideAppBootstrap(),
        provideMyBetsBase(),
        provideHome(),
        provideRootStateStorage(),
        provideMarketSubTypeLogoHideStrategy(),
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfigProvider,
        },
        {
            provide: NG_SCROLLBAR_OPTIONS,
            useClass: NgScrollbarOptionsProvider,
        },
        { provide: STORE_PREFIX, useValue: 'ms2.' },
        CurrencyPipe,
        RootInjector,
        DispatcherService,
        provideMarqueeWidgetSkeleton(),
        { provide: MarketDisplayFallbackService, useClass: SportsMarketGroupingFallbackService },
        { provide: MediaQueryService, useClass: PatchedMediaObserver },
        {
            provide: CustomLoadingIndicatorService,
            useExisting: CustomLoadingIndicatorExtendedService,
        },
        {
            provide: LANGUAGE_SWITCHER_URLS_PROVIDER,
            useClass: PrettyUrlLanguageSwitcherProvider,
        },
        {
            provide: WidgetRefreshProcessor,
            useClass: WidgetContextRefreshProcessor,
        },
        { provide: LOADING_INDICATOR, useValue: '' },
        {
            provide: PAGE_VIEW_DATA_PROVIDER,
            useClass: PrettyUrlPageViewDataProvider,
            multi: true,
        },
        {
            provide: ROUTE_PROCESSOR,
            useClass: PrettyUrlRouteProcessor,
            multi: true,
        },
        {
            provide: SCROLL_ADAPTER_CONFIG,
            useValue: SCROLL_ADAPTER_CONFIG_OVERRIDE,
        },
        {
            provide: ACCA_BOOST_REFRESH_TRIGGER,
            useClass: AccaBoostRefreshTriggerService,
        },
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                let result = config.src;
                if (result.indexOf('?') < 0) {
                    result += '?';
                } else {
                    result += '&';
                }

                if (!config.src.endsWith('.svg') && config.src.indexOf('scmedia') >= 0 && config.width) {
                    result += `w=${config.width}&`;
                }

                if (result.endsWith('?') || result.endsWith('&')) {
                    result = result.slice(0, result.length - 1);
                }

                return result;
            },
        },
        provideCds(),
        provideRunAfterLcp(),
        provideSafariBrowserToken(),
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        setAppInjector(injector);
    }
}
